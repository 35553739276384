import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
} from '@material-ui/core'

import Layout from '../components/Layout'
import NotFoundImg from '../img/404.png'
import PageContainer from '../components/PageContainer'

const styles = () => {
  return createStyles({
    image: {
      width: '100%',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

function NotFound({ classes }: Props) {
  return (
    <Layout>
      <PageContainer>
        <Typography variant="h1">Page not found.</Typography>
        <img
          src={NotFoundImg}
          alt="Woman standing in front of giant 404 numbers."
          className={classes.image}
        />
        <Typography variant="body1">
          I couldn&apos;t find the requested page. Please try another page.
        </Typography>
      </PageContainer>
    </Layout>
  )
}

export default withStyles(styles)(NotFound)
